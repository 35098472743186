/*=========================================================================================
  File Name: moduleBranchMutations.js
  Description: AllCompanyParty Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vaagai Tecknowledge - Easy Admin
  Author: Web Team
  Author URL: http://www.vaagai.org.in
==========================================================================================*/


export default {
  SET_STATUS_FLAG(state, payload) {
    state.StatusFlag = payload;
  },
  SET_DATA(state, payload) {
    console.log('SET_DATA called', { payload });
    state.data = payload;
  },
  LOAD_ALL_RECORDS(state, records) {
    state.dataArray = records;
  }
}
