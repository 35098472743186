<template>
  <div class="about" id="default-layout">
    <v-app>
      <body
        id="kt_body"
        class="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed bg-light-primary"
        style="
          --kt-toolbar-height: 5px;
          --kt-toolbar-height-tablet-and-mobile: 5px;
        "
      >
        <!--begin::Main-->
        <!--begin::Root-->
        <div class="d-flex flex-column flex-root mt-3">
          <!--begin::Page-->
          <div class="page d-flex flex-row flex-column-fluid">
            <!--begin::Wrapper-->
            <div
              class="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <top-bar />
              <!--begin::Content-->
              <div
                class="content d-flex flex-column flex-column-fluid mx-8"
                id="kt_content"
              >
                <!-- <tool-bar /> -->
                <slot />
                <!-- <content-section /> -->
                <!--end::Content-->
              </div>

              <default-footer />
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Page-->
        </div>
        <!--end::Root-->
        <!-- <card-section /> -->
        <!-- <right-center-button /> -->
      </body>
    </v-app>
  </div>
</template>

<script>
import TopBar from "@/components/TopBar.vue";
// import ToolBar from "@/components/ToolBar.vue";
// import ContentSection from "@/components/ContentSection.vue";
import DefaultFooter from "@/components/DefaultFooter.vue";
// import CardSection from "@/components/CardSection.vue";
// import RightCenterButton from "@/components/RightCenterButton.vue";

export default {
  components: {
    TopBar,
    // ToolBar,
    // ContentSection,
    DefaultFooter,
    // CardSection,
    // RightCenterButton,
  },
};
</script>