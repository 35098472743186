// eng, tam, hin, tel, mal, kan
export default {
	eng: {

		Address: 'Address',
		AllLedgers: 'All Ledgers',
		AllLedgersCreateDescription: 'Create a new ledger',
		AllLedgersCreateTitle: 'Add Ledger',
		AllLedgersDescription: 'Displays list of all ledgers',
		AllLedgersTitle: 'All ledgers list',
		Amount: 'Amount',
		Assets: 'Assets',
		CancelButton: 'Cancel',
		ResetButton: 'Reset',
		CESSCreateDesciption: 'Create a new CESS rule',
		CESSCreateTitle: 'Add CESS Rule',
		CESSTab: 'CESS Tax',
		CESSTabDescription: 'Create CESS tax rules',
		CESSTabTitle: 'CESS Tax',
		CGST: 'CGST',
		City: 'City',
		CityCreateTitle: 'Add a new city',
		CityTitle: 'City List',
		ClosingBalance: 'Closing Balance',
		ContactNumber: 'Contact Details',
		ContraList: 'Contra Entries',
		CreditDebit: 'Credit or Debit',
		CurrentBalance: 'Current Balance',
		CurrentStock: 'Current Stock',
		CustomerCreateDescription: 'Create a new Customer',
		CustomerCreateTitle: 'New Customer',
		CustomerDetails: 'Customer Details',
		CustomerName: 'Customer Name',
		CustomerPageTitle: 'Customers List',
		CustomerPriceGroup: 'Customer Price Group',
		Customers: 'Customers',
		Date: 'Date',
		DeliveryDetails: 'Delivery Details',
		Description: 'Description',
		District: 'District',
		EmployeeCreateDescription: 'Create a new Employee',
		EmployeeCreateTitle: 'New Employee',
		EmployeeDetails: 'Employee Details',
		EmployeeName: 'Employee Name',
		EmployeePageTitle: 'Employees List',
		EmployeePriceGroup: 'Price Group',
		Employees: 'Employees',
		Expenses: 'Expenses',
		FixedValue: 'Fixed Value',
		FromDate: 'From Date',
		GroupName: 'Group Name',
		GST: 'GST %',
		GSTSlabCreateTitle: 'Add GST Tax',
		GSTSlabTab: 'GST Tax',
		GSTSlabTitle: 'GST Tax',
		GSTSlabValue: 'GST Tax Value',
		HighestCess: 'Highest Cess Calculation',
		IGST: 'IGST',
		Income: 'Income',
		InvoiceAmount: 'Invoice Amount',
		InvoiceNumber: 'Invoice Number',
		InvoiceSubTotal: 'Taxable Value',
		InvoiceTotal: 'Invoice Total',
		JournalList: 'Journal Entries',
		LastInvoiceNumber: 'Last Sales Invoice Number',
		LedgerCategory: 'Ledger Category',
		LedgerDetails: 'Ledger Details',
		LedgerGroup: 'Ledger Group',
		LedgerGroupCreateDescription: 'Create a new ledger group',
		LedgerGroupCreateTitle: 'Add Ledger Group',
		LedgerGroupDescription: 'List of all ledger groups',
		LedgerGroupTitle: 'Ledger Group List',
		LedgerName: 'Ledger Name',
		LedgerStatement: 'Ledger Statement',
		LedgerStatementDescription: 'Displays the transaction per ledger within a date range or the entire statement',
		LedgerStatementTitle: 'Ledger Statement',
		Liabilities: 'Liabilities',
		ListButton: 'List',
		Login: 'Login creation',
		Logout: 'Logout',
		OpeningBalance: 'Opening Balance',
		ParentGroup: 'Parent Group',
		PaymentCreateDescription: 'Enter the new payment',
		PaymentCreateTitle: 'Add Payment',
		PaymentDescription: 'List of all payment entries',
		PaymentList: 'Payment Entries',
		PaymentMode: 'Payment Mode',
		PaymentModeDescription: 'List of payment modes',
		PaymentModeName: 'Payment Mode Name',
		PaymentModeTitle: 'Mode of payment',
		PaymentType: 'Payment Type',
		Percentage: 'Percentage',
		PerQty: 'Per Qty',
		Phone: 'Phone',
		Price: 'Price (Rs.)',
		PriceGroup: 'Price Group',
		PriceUpdate: 'Price Update',
		PriceUpdateDescription: 'Update the price of products for the corresponding price group',
		PriceUpdateTitle: 'Product Price Update',
		ProductCode: 'Product Code',
		ProductCreateDescription: 'Create a new product',
		ProductCreateTitle: 'Add product',
		ProductCustomCode: 'Product Custom Code',
		ProductEditDescription: 'Make changes to an existing product',
		ProductEditTitle: 'Edit a product',
		ProductGroupCreateTitle: 'Create product group',
		ProductGroupName: 'Product Group Name',
		ProductGroupTab: 'Products Group',
		ProductGroupTitle: 'Product Group list',
		ProductImage: 'Product Image',
		ProductionCost: 'Production Cost',
		ProductName: 'Product Name',
		Products: 'Products',
		ProductsTab: 'Products',
		ProductsTabTitle: 'List of products in the inventory',
		ProductStatement: 'Product Statement',
		ProductStatementDescription: 'Displays the product transaction with a date range or from the beginning',
		ProductStatementTitle: 'Product Transaction Statement',
		ProductUnit: 'Product Unit',
		ProductVarientCreateDescription: 'Create a new varient to the existing product',
		ProductVarientCreateTitle: 'Add varient product',
		Purchase: 'Purchase',
		PurchaseCreateDescription: 'Add a new purchase bill',
		PurchaseCreateTitle: 'New Purchase invoice',
		PurchasePageDescription: 'Shows the list of all purchases',
		PurchasePageTItle: 'All Purchases list',
		Quantity: 'Quantity',
		ReceiptCreateDescription: 'Add a new receipt',
		ReceiptCreateTitle: 'Add Receipt',
		ReceiptList: 'Receipt Entries',
		ReceiptTabDescription: 'List of all receipt entries',
		ReceivedAmount: 'Received Amount',
		ReferenceNumber: 'Reference Number',
		RoundOff: 'Round Off',
		Sales: 'Sales',
		SalesCreateDescription: 'Create a new sales bill',
		SalesCreateTitle: 'New Sales',
		SalesList: 'Sales List',
		SalesPageDescription: 'Shows the list of all Sales',
		SalesPageTitle: 'All Sales list',
		Settings: 'Settings',
		SGST: 'SGST',
		StartingDate: 'Starting Date',
		State: 'State',
		Status: 'Status',
		SubmitButton: 'Submit',
		SubTotal: 'Sub Total',
		SupplierCreateDescription: 'Create a new supplier',
		SupplierCreateTitle: 'New Supplier',
		SupplierDetails: 'Supplier Details',
		SupplierName: 'Supplier Name',
		SupplierPageTitle: 'Suppliers List',
		SupplierPriceGroup: 'Price Group',
		Suppliers: 'Suppliers',
		Taxable: 'Taxable',
		ToDate: 'To Date',
		Transaction: 'Transaction',
		TransportList: 'Transport List',
		TransportListTitle: 'Transport Details List',
		TransportName: 'Transport Name',
		Type: 'Type',
		UnitSymbol: 'Unit Symbol',
		UnitTab: 'Units',
		UnitTabTitle: 'Product units',
		Users: 'Users',

		Submit: 'Submit',
		Cancel: 'Cancel',
		ActiveStatus: 'Active Status',
		Dashboard: 'Dashboard',
		eCommerce: 'eCommerce',
		Apps: 'Apps',
		Todo: 'Todo',
		Chat: 'Chat',
		Email: 'Email',
		Calendar: 'Calendar',
		FullCalendar: 'Full Calendar',
		SimpleCalendar: 'Simple Calendar',
		Shop: 'Shop',
		Cart: 'Cart',
		WishList: 'Wish List',
		Checkout: 'Checkout',
		UIElements: 'UI Elements',
		DataList: 'Data List',
		ListView: 'List View',
		ThumbView: 'Thumb View',
		Grid: 'Grid',
		Vuesax: 'Vuesax',
		Tailwind: 'Tailwind',
		Colors: 'Colors',
		Card: 'Card',
		Basic: 'Basic',
		Statistics: 'Statistics',
		Analytics: 'Analytics',
		CardActions: 'Card Actions',
		CardColors: 'Card Colors',
		Table: 'Table',
		agGridTable: 'agGrid Table',
		Components: 'Components',
		Alert: 'Alert',
		Avatar: 'Avatar',
		Badge: 'Badge',
		Breadcrumb: 'Breadcrumb',
		Button: 'Button',
		ButtonGroup: 'Button Group',
		Chip: 'Chip',
		Collapse: 'Collapse',
		Dialogs: 'Dialogs',
		Divider: 'Divider',
		DropDown: 'DropDown',
		List: 'List',
		Loading: 'Loading',
		Navbar: 'Navbar',
		Notifications: 'Notifications',
		Pagination: 'Pagination',
		Popup: 'Popup',
		Progress: 'Progress',
		Sidebar: 'Sidebar',
		Slider: 'Slider',
		Tabs: 'Tabs',
		Tooltip: 'Tooltip',
		Upload: 'Upload',
		FormsAndTable: 'Forms & Table',
		FormElements: 'Form Elements',
		Select: 'Select',
		Switch: 'Switch',
		Checkbox: 'Checkbox',
		Radio: 'Radio',
		Input: 'Input',
		NumberInput: 'Number Input',
		Textarea: 'Textarea',
		FormLayouts: 'Form Layouts',
		FormWizard: 'Form Wizard',
		FormValidation: 'Form Validation',
		FormInputGroup: 'Form Input Group',
		Pages: 'Pages',
		Authentication: 'Authentication',
		Register: 'Register',
		ForgotPassword: 'Forgot Password',
		ResetPassword: 'Reset Password',
		LockScreen: 'Lock Screen',
		Miscellaneous: 'Miscellaneous',
		ComingSoon: 'Coming Soon',
		Error: 'Error',
		404: '404',
		500: '500',
		NotAuthorized: 'Not Authorized',
		Maintenance: 'Maintenance',
		Profile: 'Profile',
		Invoice: 'Invoice',
		FAQ: 'FAQ',
		Search: 'Search',
		KnowledgeBase: 'Knowledge Base',
		ChartsAndMaps: 'Charts & Maps',
		Charts: 'Charts',
		ApexCharts: 'Apex Charts',
		chartjs: 'chartjs',
		echarts: 'echarts',
		chartist: 'chartist',
		GoogleMap: 'Google Map',
		Extensions: 'Extensions',
		QuillEditor: 'Quill Editor',
		DragAndDrop: 'Drag & Drop',
		Datepicker: 'Datepicker',
		DatetimePicker: 'Datetime Picker',
		AccessControl: 'Access Control',
		EditorView: 'Editor View',
		AdminView: 'Admin View',
		I18n: 'I18n',
		Carousel: 'Carousel',
		Clipboard: 'Clipboard',
		ContextMenu: 'Context Menu',
		StarRatings: 'Star Ratings',
		Autocomplete: 'Autocomplete',
		Tree: 'Tree',
		Others: 'Others',
		MenuLevels: 'Menu Levels',
		MenuLevel2p1: 'Menu Level 2.1',
		MenuLevel2p2: 'Menu Level 2.2',
		MenuLevel3p1: 'Menu Level 3.1',
		MenuLevel3p2: 'Menu Level 3.2',
		DisabledMenu: 'Disabled Menu',
		Support: 'Support',
		Documentation: 'Documentation',
		RaiseSupport: 'Raise Support',
		demoTitle: 'Card Title',
		demoText: 'Cake sesame snaps cupcake gingerbread danish I love gingerbread. Apple pie pie jujubes chupa chups muffin halvah lollipop. Chocolate cake oat cake tiramisu marzipan sugar plum. Donut sweet pie oat cake dragée fruitcake cotton candy lemon drops.',
		ChangePasswordTitle: 'Change password of logged in user',
		ProductionTitle: 'Production List',
		ProductionStart: 'Start Production',
		ProductionStop: 'Stop Production',
		BranchList: 'Branch Settings',
		BranchCreate: 'Create New Branch',
		ProductionRulesUpdate: 'Update Production Rule',
		ChatNew: 'New Chat Entry',
		ChatCustomer: 'Chat with customer',
	},

	tam: {

		Address: 'முகவரி',
		AllLedgers: 'பேரேடுகள்',
		AllLedgersCreateDescription: 'புதிய பேரேடு உருவாக்கு',
		AllLedgersCreateTitle: 'பேரேடு பெயர் உருவாக்கு',
		AllLedgersDescription: ' அனைத்து பேரேடுகள் பட்டியலையும் காட்டுகிறது',
		AllLedgersTitle: 'அனைத்து பேரேடுகள் பட்டியல்',
		Amount: 'தொகை',
		Assets: 'சொத்துக்கள்',
		CancelButton: 'ரத்துசெய்',
		ResetButton: 'Reset',
		CESSCreateDesciption: 'புதிய CESS விதியை உருவாக்கவும்',
		CESSCreateTitle: 'CESS விதியைச் சேர்க்க',
		CESSTab: 'CESS வரி',
		CESSTabDescription: 'CESS வரி விதிகளை உருவாக்கவும்',
		CESSTabTitle: 'CESS வரி',
		CGST: 'CGST',
		City: 'நகரம்',
		CityCreateTitle: 'புதிய நகரத்தைச் சேர்க்கவும்',
		CityTitle: 'நகர பட்டியல்',
		ClosingBalance: 'கடந்த இருப்பு',
		ContactNumber: 'தொடர்பு விபரங்கள்',
		ContraList: 'எதிர் உள்ளீடுகள்',
		CreditDebit: 'கடன் (அ) பற்று',
		CurrentBalance: 'தற்போதைய இருப்பு',
		CurrentStock: 'சரக்கு இருப்பு',
		CustomerCreateDescription: 'புதிய வாடிக்கையாளர் உருவாக்கவும்',
		CustomerCreateTitle: 'புதிய வாடிக்கையாளர்',
		CustomerDetails: 'வாடிக்கையாளர் விவரங்கள்',
		CustomerName: 'வாடிக்கையாளர் பெயர்',
		CustomerPageTitle: 'வாடிக்கையாளர்கள் பட்டியல்',
		CustomerPriceGroup: 'விலை பட்டியல்',
		Customers: 'வாடிக்கையாளர்கள்',
		Date: 'தேதி',
		DeliveryDetails: 'விநியோக விவரங்கள்',
		Description: 'விளக்கம்',
		District: 'மாவட்டம்',
		EmployeeCreateDescription: 'புதிய ஊழியர் உருவாக்கவும்',
		EmployeeCreateTitle: 'புதிய ஊழியர்',
		EmployeeDetails: 'ஊழியர் விவரங்கள்',
		EmployeeName: 'ஊழியர் பெயர்',
		EmployeePageTitle: 'ஊழியர்கள் பட்டியல்',
		EmployeePriceGroup: 'விலை பட்டியல்',
		Employees: 'ஊழியர்கள்',
		Expenses: 'செலவுகள்',
		FixedValue: 'நிலையான மதிப்பு',
		FromDate: 'ஆரம்ப தேதி',
		GroupName: 'குழு பெயர்',
		GST: 'GST %',
		GSTSlabCreateTitle: 'GST வரி சேர்க்கவும்',
		GSTSlabTab: 'GST வரி',
		GSTSlabTitle: 'GST வரிகள்',
		GSTSlabValue: 'GST வரி மதிப்பு',
		HighestCess: 'அதிகபட்ச CESS கணக்கீடு',
		IGST: 'IGST',
		Income: 'வருமானம்',
		InvoiceAmount: 'ரசிது தொகை',
		InvoiceNumber: 'ரசிது எண்',
		InvoiceSubTotal: 'வரி செலுத்தக்கூடிய மதிப்பு',
		InvoiceTotal: 'மொத்த தொகை',
		JournalList: 'பத்திரிகை உள்ளீடுகள்',
		LastInvoiceNumber: 'கடைசி விற்பனை ரசிது எண்',
		LedgerCategory: 'பேரேடு வகை',
		LedgerDetails: 'பேரேடு விவரங்கள்',
		LedgerGroup: 'பேரேடு குழு',
		LedgerGroupCreateDescription: 'புதிய குழு பெயர் உருவாக்கு',
		LedgerGroupCreateTitle: 'குழு பெயர் உருவாக்கு',
		LedgerGroupDescription: 'அனைத்து பேரேடு குழு பட்டியல்',
		LedgerGroupTitle: 'பேரேடு குழு பட்டியல்',
		LedgerName: 'பேரேடு பெயர்',
		LedgerStatement: 'பேரேடு அறிக்கை',
		LedgerStatementDescription: 'பேரேடுக்கான பரிவர்த்தனையை தேதி வரம்பிற்குள் அல்லது முழு அறிக்கையிலும் காண்பிக்கும்',
		LedgerStatementTitle: 'பேரேடு அறிக்கை',
		Liabilities: 'பொறுப்புகள்',
		ListButton: 'பட்டியல்',
		Login: 'லாகின் உருவாக்கம்',
		Logout: 'வெளியேறு',
		OpeningBalance: 'ஆரம்ப இருப்பு',
		ParentGroup: 'மேல் குழு',
		PaymentCreateDescription: 'புதிய கட்டணத்தை உள்ளிடவும்',
		PaymentCreateTitle: 'கட்டணம் சேர்க்கவும்',
		PaymentDescription: 'அனைத்து கட்டண உள்ளீடுகளின் பட்டியல்',
		PaymentList: 'கட்டண உள்ளீடுகள்',
		PaymentMode: 'கட்டண முறை',
		PaymentModeDescription: 'பரிவர்த்தனை பயன்முறையின் பட்டியல்',
		PaymentModeName: 'கட்டண முறை பெயர்',
		PaymentModeTitle: 'பரிவர்த்தனை முறை',
		PaymentType: 'கட்டணம் வகை',
		Percentage: 'சதவிதம்',
		PerQty: 'அளவு',
		Phone: 'தொலைபேசி',
		Price: 'விலை (Rs.)',
		PriceGroup: 'விலை பட்டியல்',
		PriceUpdate: 'விலை புதுப்பிப்பு',
		PriceUpdateDescription: 'அந்தந்த விலைக் குழுவிற்கான சரக்குகளின் விலையைப் புதுப்பிக்கவும்',
		PriceUpdateTitle: 'சரக்கு விலை புதுப்பிப்பு',
		ProductCode: 'சரக்கு குறியீடு',
		ProductCreateDescription: 'புதிய சரக்கு உருவாக்கவும்',
		ProductCreateTitle: 'சரக்கு சேர்க்க',
		ProductCustomCode: 'சரக்குகளின் குறியீடு',
		ProductEditDescription: 'ஏற்கனவே உள்ள சரக்குக்கு மாற்றங்களைச் செய்யுங்கள்',
		ProductEditTitle: 'ஒரு சரக்கை திருத்தவும்',
		ProductGroupCreateTitle: 'சரக்கு குழுவை உருவாக்கவும்',
		ProductGroupName: 'சரக்கு குழு பெயர்',
		ProductGroupTab: 'சரக்கு வகைகள்',
		ProductGroupTitle: 'சரக்கு குழு பட்டியல்',
		ProductImage: 'சரக்கு படம்',
		ProductionCost: 'உற்பத்தி செலவு',
		ProductName: 'சரக்கு பெயர்',
		Products: 'சரக்குகள்',
		ProductsTab: 'சரக்குகள்',
		ProductsTabTitle: 'சரக்குகள் பட்டியல்',
		ProductStatement: 'சரக்கு அறிக்கை',
		ProductStatementDescription: 'சரக்கு பரிவர்த்தனையை தேதி வரம்புடன் அல்லது தொடக்கத்திலிருந்து காட்டுகிறது',
		ProductStatementTitle: 'சரக்கு பரிவர்த்தனை அறிக்கை',
		ProductUnit: 'சரக்கு அலகுகள்',
		ProductVarientCreateDescription: 'இருக்கும் சரக்குக்கு புதிய மாறுபாட்டை உருவாக்கவும்',
		ProductVarientCreateTitle: 'மாறுபட்ட தயாரிப்பு சேர்க்கவும்',
		Purchase: 'கொள்முதல்',
		PurchaseCreateDescription: 'புதிய கொள்முதல் ரசீதை ஏற்றுக',
		PurchaseCreateTitle: 'புதிய கொள்முதல்',
		PurchasePageDescription: 'அனைத்து கொள்முதல் பட்டியல்களை காட்டுகிறது',
		PurchasePageTItle: 'அனைத்து கொள்முதல் பட்டியல்',
		Quantity: 'எண்ணிக்கை',
		ReceiptCreateDescription: 'புதிய ரசீதைச் சேர்க்கவும்',
		ReceiptCreateTitle: 'ரசீது உருவாக்கு',
		ReceiptList: 'ரசீது உள்ளீடுகள்',
		ReceiptTabDescription: 'அனைத்து ரசீது உள்ளீடுகளின் பட்டியல்',
		ReceivedAmount: 'பெறப்பட்ட தொகை',
		ReferenceNumber: 'குறிப்பு எண்',
		RoundOff: 'சுற்று முடக்கு',
		Sales: 'விற்பனைகள்',
		SalesCreateDescription: 'புதிய விற்பனை ரசீதை ஏற்றுக',
		SalesCreateTitle: 'புதிய விற்பனை',
		SalesList: 'விற்பனை பட்டியல்',
		SalesPageDescription: 'அனைத்து விற்பனை பட்டியல்களை காட்டுகிறது',
		SalesPageTitle: 'அனைத்து விற்பனை பட்டியல்',
		Settings: 'அமைப்புகள்',
		SGST: 'SGST',
		StartingDate: 'தொடக்க நாள்',
		State: 'மாநிலம்',
		Status: 'நிலை',
		SubmitButton: 'சமர்ப்பி',
		SubTotal: 'கூட்டுத்தொகை',
		SupplierCreateDescription: 'புதிய விற்பனையாளர் உருவாக்கவும்',
		SupplierCreateTitle: 'புதிய விற்பனையாளர்',
		SupplierDetails: 'விற்பனையாளர் விவரங்கள்',
		SupplierName: 'விற்பனையாளர் பெயர்',
		SupplierPageTitle: 'விற்பனையாளர்கள் பட்டியல்',
		SupplierPriceGroup: 'விலை பட்டியல்',
		Suppliers: 'விற்பனையாளர்கள்',
		Taxable: 'வரி',
		ToDate: 'முடியும் தேதி',
		Transaction: 'பரிவர்த்தனைகள்',
		TransportList: 'போக்குவரத்து பட்டியல்',
		TransportListTitle: 'போக்குவரத்து விவரங்கள் பட்டியல்',
		TransportName: 'போக்குவரத்து பெயர்',
		Type: 'வகை',
		UnitSymbol: 'அலகு சின்னம்',
		UnitTab: 'அலகுகள்',
		UnitTabTitle: 'சரக்கு அலகுகள்',
		Users: 'நிர்வாகிகள்',

		Submit: 'Tamil Submit',
		Cancel: 'Tamil Cancel',
		ActiveStatus: 'செயலில் உள்ள நிலை',
		Dashboard: 'Tamil Dashboard',
		eCommerce: 'eCommerce',
		Apps: 'Apps',
		Todo: 'Todo',
		Chat: 'Chat',
		Email: 'Email',
		Calendar: 'Calendar',
		FullCalendar: 'Full Calendar',
		SimpleCalendar: 'Simple Calendar',
		Shop: 'Shop',
		Cart: 'Cart',
		WishList: 'Wish List',
		Checkout: 'Checkout',
		UIElements: 'UI Elements',
		DataList: 'Data List',
		ListView: 'List View',
		ThumbView: 'Thumb View',
		Grid: 'Grid',
		Vuesax: 'Vuesax',
		Tailwind: 'Tailwind',
		Colors: 'Colors',
		Card: 'Card',
		Basic: 'Basic',
		Statistics: 'Statistics',
		Analytics: 'Analytics',
		CardActions: 'Card Actions',
		CardColors: 'Card Colors',
		Table: 'Table',
		agGridTable: 'agGrid Table',
		Components: 'Components',
		Alert: 'Alert',
		Avatar: 'Avatar',
		Badge: 'Badge',
		Breadcrumb: 'Breadcrumb',
		Button: 'Button',
		ButtonGroup: 'Button Group',
		Chip: 'Chip',
		Collapse: 'Collapse',
		Dialogs: 'Dialogs',
		Divider: 'Divider',
		DropDown: 'DropDown',
		List: 'List',
		Loading: 'Loading',
		Navbar: 'Navbar',
		Notifications: 'Notifications',
		Pagination: 'Pagination',
		Popup: 'Popup',
		Progress: 'Progress',
		Sidebar: 'Sidebar',
		Slider: 'Slider',
		Tabs: 'Tabs',
		Tooltip: 'Tooltip',
		Upload: 'Upload',
		FormsAndTable: 'Forms & Table',
		FormElements: 'Form Elements',
		Select: 'Select',
		Switch: 'Switch',
		Checkbox: 'Checkbox',
		Radio: 'Radio',
		Input: 'Input',
		NumberInput: 'Number Input',
		Textarea: 'Textarea',
		FormLayouts: 'Form Layouts',
		FormWizard: 'Form Wizard',
		FormValidation: 'Form Validation',
		FormInputGroup: 'Form Input Group',
		Pages: 'Pages',
		Authentication: 'Authentication',
		Register: 'Register',
		ForgotPassword: 'Forgot Password',
		ResetPassword: 'Reset Password',
		LockScreen: 'Lock Screen',
		Miscellaneous: 'Miscellaneous',
		ComingSoon: 'Coming Soon',
		Error: 'Error',
		404: '404',
		500: '500',
		NotAuthorized: 'Not Authorized',
		Maintenance: 'Maintenance',
		Profile: 'Profile',
		Invoice: 'Invoice',
		FAQ: 'FAQ',
		Search: 'Search',
		KnowledgeBase: 'Knowledge Base',
		ChartsAndMaps: 'Charts & Maps',
		Charts: 'Charts',
		ApexCharts: 'Apex Charts',
		chartjs: 'chartjs',
		echarts: 'echarts',
		chartist: 'chartist',
		GoogleMap: 'Google Map',
		Extensions: 'Extensions',
		QuillEditor: 'Quill Editor',
		DragAndDrop: 'Drag & Drop',
		Datepicker: 'Datepicker',
		DatetimePicker: 'Datetime Picker',
		AccessControl: 'Access Control',
		EditorView: 'Editor View',
		AdminView: 'Admin View',
		I18n: 'I18n',
		Carousel: 'Carousel',
		Clipboard: 'Clipboard',
		ContextMenu: 'Context Menu',
		StarRatings: 'Star Ratings',
		Autocomplete: 'Autocomplete',
		Tree: 'Tree',
		Others: 'Others',
		MenuLevels: 'Menu Levels',
		MenuLevel2p1: 'Menu Level 2.1',
		MenuLevel2p2: 'Menu Level 2.2',
		MenuLevel3p1: 'Menu Level 3.1',
		MenuLevel3p2: 'Menu Level 3.2',
		DisabledMenu: 'Disabled Menu',
		Support: 'Support',
		Documentation: 'Documentation',
		RaiseSupport: 'Raise Support',
		demoTitle: 'Card Title',
		demoText: 'Cake sesame snaps cupcake gingerbread danish I love gingerbread. Apple pie pie jujubes chupa chups muffin halvah lollipop. Chocolate cake oat cake tiramisu marzipan sugar plum. Donut sweet pie oat cake dragée fruitcake cotton candy lemon drops.',
		ChangePasswordTitle: 'Change password of logged in user',
		ProductionTitle: 'Production List',
		ProductionStart: 'Start Production',
		ProductionStop: 'Stop Production',
		BranchList: 'Branch Settings',
		BranchCreate: 'Create New Branch',
		ProductionRulesUpdate: 'Update Production Rule',
		ChatNew: 'New Chat Entry',
		ChatCustomer: 'Chat with customer',
	},

	hin: {

		Address: 'Address',
		AllLedgers: 'All Ledgers',
		AllLedgersCreateDescription: 'Create a new ledger',
		AllLedgersCreateTitle: 'Add Ledger',
		AllLedgersDescription: 'Displays list of all ledgers',
		AllLedgersTitle: 'All ledgers list',
		Amount: 'Amount',
		Assets: 'Assets',
		CancelButton: 'Cancel',
		ResetButton: 'Reset',
		CESSCreateDesciption: 'Create a new CESS rule',
		CESSCreateTitle: 'Add CESS Rule',
		CESSTab: 'CESS Tax',
		CESSTabDescription: 'Create CESS tax rules',
		CESSTabTitle: 'CESS Tax',
		CGST: 'CGST',
		City: 'City',
		CityCreateTitle: 'Add a new city',
		CityTitle: 'City List',
		ClosingBalance: 'Closing Balance',
		ContactNumber: 'Contact Details',
		ContraList: 'Contra Entries',
		CreditDebit: 'Credit or Debit',
		CurrentBalance: 'Current Balance',
		CurrentStock: 'Current Stock',
		CustomerCreateDescription: 'Create a new Customer',
		CustomerCreateTitle: 'New Customer',
		CustomerDetails: 'Customer Details',
		CustomerName: 'Customer Name',
		CustomerPageTitle: 'Customers List',
		CustomerPriceGroup: 'Customer Price Group',
		Customers: 'Customers',
		Date: 'Date',
		DeliveryDetails: 'Delivery Details',
		Description: 'Description',
		District: 'District',
		EmployeeCreateDescription: 'Create a new Employee',
		EmployeeCreateTitle: 'New Employee',
		EmployeeDetails: 'Employee Details',
		EmployeeName: 'Employee Name',
		EmployeePageTitle: 'Employees List',
		EmployeePriceGroup: 'Price Group',
		Employees: 'Employees',
		Expenses: 'Expenses',
		FixedValue: 'Fixed Value',
		FromDate: 'From Date',
		GroupName: 'Group Name',
		GST: 'GST %',
		GSTSlabCreateTitle: 'Add GST Tax',
		GSTSlabTab: 'GST Tax',
		GSTSlabTitle: 'GST Tax',
		GSTSlabValue: 'GST Tax Value',
		HighestCess: 'Highest Cess Calculation',
		IGST: 'IGST',
		Income: 'Income',
		InvoiceAmount: 'Invoice Amount',
		InvoiceNumber: 'Invoice Number',
		InvoiceSubTotal: 'Taxable Value',
		InvoiceTotal: 'Invoice Total',
		JournalList: 'Journal Entries',
		LastInvoiceNumber: 'Last Sales Invoice Number',
		LedgerCategory: 'Ledger Category',
		LedgerDetails: 'Ledger Details',
		LedgerGroup: 'Ledger Group',
		LedgerGroupCreateDescription: 'Create a new ledger group',
		LedgerGroupCreateTitle: 'Add Ledger Group',
		LedgerGroupDescription: 'List of all ledger groups',
		LedgerGroupTitle: 'Ledger Group List',
		LedgerName: 'Ledger Name',
		LedgerStatement: 'Ledger Statement',
		LedgerStatementDescription: 'Displays the transaction per ledger within a date range or the entire statement',
		LedgerStatementTitle: 'Ledger Statement',
		Liabilities: 'Liabilities',
		ListButton: 'List',
		Login: 'Login creation',
		Logout: 'Logout',
		OpeningBalance: 'Opening Balance',
		ParentGroup: 'Parent Group',
		PaymentCreateDescription: 'Enter the new payment',
		PaymentCreateTitle: 'Add Payment',
		PaymentDescription: 'List of all payment entries',
		PaymentList: 'Payment Entries',
		PaymentMode: 'Payment Mode',
		PaymentModeDescription: 'List of payment modes',
		PaymentModeName: 'Payment Mode Name',
		PaymentModeTitle: 'Mode of payment',
		PaymentType: 'Payment Type',
		Percentage: 'Percentage',
		PerQty: 'Per Qty',
		Phone: 'Phone',
		Price: 'Price (Rs.)',
		PriceGroup: 'Price Group',
		PriceUpdate: 'Price Update',
		PriceUpdateDescription: 'Update the price of products for the corresponding price group',
		PriceUpdateTitle: 'Product Price Update',
		ProductCode: 'Product Code',
		ProductCreateDescription: 'Create a new product',
		ProductCreateTitle: 'Add product',
		ProductCustomCode: 'Product Custom Code',
		ProductEditDescription: 'Make changes to an existing product',
		ProductEditTitle: 'Edit a product',
		ProductGroupCreateTitle: 'Create product group',
		ProductGroupName: 'Product Group Name',
		ProductGroupTab: 'Products Group',
		ProductGroupTitle: 'Product Group list',
		ProductImage: 'Product Image',
		ProductionCost: 'Production Cost',
		ProductName: 'Product Name',
		Products: 'Products',
		ProductsTab: 'Products',
		ProductsTabTitle: 'Products List',
		ProductStatement: 'Product Statement',
		ProductStatementDescription: 'Displays the product transaction with a date range or from the beginning',
		ProductStatementTitle: 'Product Transaction Statement',
		ProductUnit: 'Product Unit',
		ProductVarientCreateDescription: 'Create a new varient to the existing product',
		ProductVarientCreateTitle: 'Add varient product',
		Purchase: 'Purchase',
		PurchaseCreateDescription: 'Add a new purchase bill',
		PurchaseCreateTitle: 'New Purchase invoice',
		PurchasePageDescription: 'Shows the list of all purchases',
		PurchasePageTItle: 'All Purchases list',
		Quantity: 'Quantity',
		ReceiptCreateDescription: 'Add a new receipt',
		ReceiptCreateTitle: 'Add Receipt',
		ReceiptList: 'Receipt Entries',
		ReceiptTabDescription: 'List of all receipt entries',
		ReceivedAmount: 'Received Amount',
		ReferenceNumber: 'Reference Number',
		RoundOff: 'Round Off',
		Sales: 'Sales',
		SalesCreateDescription: 'Create a new sales bill',
		SalesCreateTitle: 'New Sales',
		SalesList: 'Sales List',
		SalesPageDescription: 'Shows the list of all purchases',
		SalesPageTitle: 'All Sales list',
		Settings: 'Settings',
		SGST: 'SGST',
		StartingDate: 'Starting Date',
		State: 'State',
		Status: 'Status',
		SubmitButton: 'Submit',
		SubTotal: 'Sub Total',
		SupplierCreateDescription: 'Create a new supplier',
		SupplierCreateTitle: 'New Supplier',
		SupplierDetails: 'Supplier Details',
		SupplierName: 'Supplier Name',
		SupplierPageTitle: 'Suppliers List',
		SupplierPriceGroup: 'Price Group',
		Suppliers: 'Suppliers',
		Taxable: 'Taxable',
		ToDate: 'To Date',
		Transaction: 'Transaction',
		TransportList: 'Transport List',
		TransportListTitle: 'Transport Details List',
		TransportName: 'Transport Name',
		Type: 'Type',
		UnitSymbol: 'Unit Symbol',
		UnitTab: 'Units',
		UnitTabTitle: 'Product units',
		Users: 'Users',

		Submit: 'Submit',
		Cancel: 'Cancel',
		ActiveStatus: 'Active Status',
		Dashboard: 'Hindi Dashboard',
		eCommerce: 'eCommerce',
		Apps: 'Apps',
		Todo: 'Machen',
		Chat: 'Plaudern',
		Email: 'Email',
		Calendar: 'Kalender',
		FullCalendar: 'Calendrier completVollständiger Kalender',
		SimpleCalendar: 'Einfacher Kalender',
		Shop: 'Geschäft',
		Cart: 'Wagen',
		WishList: 'Wunschzettel',
		Checkout: 'Auschecken',
		UIElements: 'Elemente der Benutzeroberfläche',
		DataList: 'Datenliste',
		ListView: 'Listenansicht',
		ThumbView: 'Daumenansicht',
		Grid: 'Gitter',
		Vuesax: 'Vuesax',
		Tailwind: 'Tailwind',
		Colors: 'Farben',
		Card: 'Karte',
		Basic: 'Basic',
		Statistics: 'Statistiken',
		Analytics: 'Analytics',
		CardActions: 'Kartenaktionen',
		CardColors: 'Kartenfarben',
		Table: 'Tabelle',
		agGridTable: 'agGrid-Tabelle',
		Components: 'Komponenten',
		Alert: 'Warnen',
		Avatar: 'Benutzerbild',
		Badge: 'Abzeichen',
		Breadcrumb: 'Breadcrumb',
		Button: 'Taste',
		ButtonGroup: 'Schaltflächengruppe',
		Chip: 'Chip',
		Collapse: 'Zusammenbruch',
		Dialogs: 'Dialoge',
		Divider: 'Teiler',
		DropDown: 'Dropdown-Liste',
		List: 'Liste',
		Loading: 'Wird geladen',
		Navbar: 'Navbar',
		Notifications: 'Benachrichtigungen',
		Pagination: 'Seitennummerierung',
		Popup: 'Pop-up',
		Progress: 'Fortschritt',
		Sidebar: 'Seitenleiste',
		Slider: 'Schieberegler',
		Tabs: 'Tabs',
		Tooltip: 'QuickInfo',
		Upload: 'Hochladen',
		FormsAndTable: 'Formulare und Tabelle',
		FormElements: 'Formularelemente',
		Select: 'Wählen',
		Switch: 'Schalter',
		Checkbox: 'Ankreuzfeld',
		Radio: 'Radio',
		Input: 'Eingang',
		NumberInput: 'Nummer eingeben',
		Textarea: 'Textbereich',
		FormLayouts: 'Formularlayouts',
		FormWizard: 'Formzauberer',
		FormValidation: 'Formularvalidierung',
		FormInputGroup: 'Formulareingabegruppe',
		Pages: 'Seiten',
		Authentication: 'Authentifizierung',
		Register: 'Registrieren',
		ForgotPassword: 'Passwort vergessen',
		ResetPassword: 'Passwort zurücksetzen',
		LockScreen: 'Bildschirm sperren',
		Miscellaneous: 'Verschiedenes',
		ComingSoon: 'Demnächst',
		Error: 'Error',
		404: '404',
		500: '500',
		NotAuthorized: 'Nicht berechtigt',
		Maintenance: 'Instandhaltung',
		Profile: 'Profil',
		Invoice: 'Rechnung',
		FAQ: 'FAQ',
		Search: 'Suche',
		KnowledgeBase: 'Wissensbasis',
		ChartsAndMaps: 'Diagramme und Karten',
		Charts: 'Diagramme',
		ApexCharts: 'Apex Charts',
		chartjs: 'chartjs',
		echarts: 'echarts',
		chartist: 'chartist',
		GoogleMap: 'Google Karte',
		Extensions: 'Erweiterungen',
		QuillEditor: 'Quill-Editor',
		DragAndDrop: 'Ziehen und loslassen',
		Datepicker: 'Datumsauswahl',
		DatetimePicker: 'Datum / Uhrzeit-Auswahl',
		AccessControl: 'Zugangskontrolle',
		EditorView: 'Editoransicht',
		AdminView: 'Admin View',
		I18n: 'I18n',
		Carousel: 'Karussell',
		Clipboard: 'Zwischenablage',
		ContextMenu: 'Kontextmenü',
		StarRatings: 'Sterne Bewertungen',
		Autocomplete: 'Autovervollständigung',
		Tree: 'Baum',
		Others: 'Andere',
		MenuLevels: 'Menüebenen',
		MenuLevel2p1: 'Menüebene 2.1',
		MenuLevel2p2: 'Menüebene 2.2',
		MenuLevel3p1: 'Menüebene 3.1',
		MenuLevel3p2: 'Menüebene 3.2',
		DisabledMenu: 'Deaktiviertes Menü',
		Support: 'Unterstützung',
		Documentation: 'Dokumentation',
		RaiseSupport: 'Unterstützung erheben',
		demoTitle: 'Kartentitel',
		demoText: 'Kuchen Sesam Schnaps Cupcake Lebkuchen Dänisch Ich liebe Lebkuchen. Apfelkuchen-Torte-Jujuben chupa chups muffin halvah Lutscher. Schokoladenkuchenhaferkuchen Tiramisu-Marzipanzuckerpflaume. Donut süße Kuchen Hafer Kuchen Dragée Obstkuchen Zuckerwatte Zitrone Tropfen.',
		ChangePasswordTitle: 'Change password of logged in user',
		ProductionTitle: 'Production List',
		ProductionStart: 'Start Production',
		ProductionStop: 'Stop Production',
		BranchList: 'Branch Settings',
		BranchCreate: 'Create New Branch',
		ProductionRulesUpdate: 'Update Production Rule',
		ChatNew: 'New Chat Entry',
		ChatCustomer: 'Chat with customer',
	},

	tel: {

		Address: 'Address',
		AllLedgers: 'All Ledgers',
		AllLedgersCreateDescription: 'Create a new ledger',
		AllLedgersCreateTitle: 'Add Ledger',
		AllLedgersDescription: 'Displays list of all ledgers',
		AllLedgersTitle: 'All ledgers list',
		Amount: 'Amount',
		Assets: 'Assets',
		CancelButton: 'Cancel',
		ResetButton: 'Reset',
		CESSCreateDesciption: 'Create a new CESS rule',
		CESSCreateTitle: 'Add CESS Rule',
		CESSTab: 'CESS Tax',
		CESSTabDescription: 'Create CESS tax rules',
		CESSTabTitle: 'CESS Tax',
		CGST: 'CGST',
		City: 'City',
		CityCreateTitle: 'Add a new city',
		CityTitle: 'City List',
		ClosingBalance: 'Closing Balance',
		ContactNumber: 'Contact Details',
		ContraList: 'Contra Entries',
		CreditDebit: 'Credit or Debit',
		CurrentBalance: 'Current Balance',
		CurrentStock: 'Current Stock',
		CustomerCreateDescription: 'Create a new Customer',
		CustomerCreateTitle: 'New Customer',
		CustomerDetails: 'Customer Details',
		CustomerName: 'Customer Name',
		CustomerPageTitle: 'Customers List',
		CustomerPriceGroup: 'Customer Price Group',
		Customers: 'Customers',
		Date: 'Date',
		DeliveryDetails: 'Delivery Details',
		Description: 'Description',
		District: 'District',
		EmployeeCreateDescription: 'Create a new Employee',
		EmployeeCreateTitle: 'New Employee',
		EmployeeDetails: 'Employee Details',
		EmployeeName: 'Employee Name',
		EmployeePageTitle: 'Employees List',
		EmployeePriceGroup: 'Price Group',
		Employees: 'Employees',
		Expenses: 'Expenses',
		FixedValue: 'Fixed Value',
		FromDate: 'From Date',
		GroupName: 'Group Name',
		GST: 'GST %',
		GSTSlabCreateTitle: 'Add GST Tax',
		GSTSlabTab: 'GST Tax',
		GSTSlabTitle: 'GST Tax',
		GSTSlabValue: 'GST Tax Value',
		HighestCess: 'Highest Cess Calculation',
		IGST: 'IGST',
		Income: 'Income',
		InvoiceAmount: 'Invoice Amount',
		InvoiceNumber: 'Invoice Number',
		InvoiceSubTotal: 'Taxable Value',
		InvoiceTotal: 'Invoice Total',
		JournalList: 'Journal Entries',
		LastInvoiceNumber: 'Last Sales Invoice Number',
		LedgerCategory: 'Ledger Category',
		LedgerDetails: 'Ledger Details',
		LedgerGroup: 'Ledger Group',
		LedgerGroupCreateDescription: 'Create a new ledger group',
		LedgerGroupCreateTitle: 'Add Ledger Group',
		LedgerGroupDescription: 'List of all ledger groups',
		LedgerGroupTitle: 'Ledger Group List',
		LedgerName: 'Ledger Name',
		LedgerStatement: 'Ledger Statement',
		LedgerStatementDescription: 'Displays the transaction per ledger within a date range or the entire statement',
		LedgerStatementTitle: 'Ledger Statement',
		Liabilities: 'Liabilities',
		ListButton: 'List',
		Login: 'Login creation',
		Logout: 'Logout',
		OpeningBalance: 'Opening Balance',
		ParentGroup: 'Parent Group',
		PaymentCreateDescription: 'Enter the new payment',
		PaymentCreateTitle: 'Add Payment',
		PaymentDescription: 'List of all payment entries',
		PaymentList: 'Payment Entries',
		PaymentMode: 'Payment Mode',
		PaymentModeDescription: 'List of payment modes',
		PaymentModeName: 'Payment Mode Name',
		PaymentModeTitle: 'Mode of payment',
		PaymentType: 'Payment Type',
		Percentage: 'Percentage',
		PerQty: 'Per Qty',
		Phone: 'Phone',
		Price: 'Price (Rs.)',
		PriceGroup: 'Price Group',
		PriceUpdate: 'Price Update',
		PriceUpdateDescription: 'Update the price of products for the corresponding price group',
		PriceUpdateTitle: 'Product Price Update',
		ProductCode: 'Product Code',
		ProductCreateDescription: 'Create a new product',
		ProductCreateTitle: 'Add product',
		ProductCustomCode: 'Product Custom Code',
		ProductEditDescription: 'Make changes to an existing product',
		ProductEditTitle: 'Edit a product',
		ProductGroupCreateTitle: 'Create product group',
		ProductGroupName: 'Product Group Name',
		ProductGroupTab: 'Products Group',
		ProductGroupTitle: 'Product Group list',
		ProductImage: 'Product Image',
		ProductionCost: 'Production Cost',
		ProductName: 'Product Name',
		Products: 'Products',
		ProductsTab: 'Products',
		ProductsTabTitle: 'Products List',
		ProductStatement: 'Product Statement',
		ProductStatementDescription: 'Displays the product transaction with a date range or from the beginning',
		ProductStatementTitle: 'Product Transaction Statement',
		ProductUnit: 'Product Unit',
		ProductVarientCreateDescription: 'Create a new varient to the existing product',
		ProductVarientCreateTitle: 'Add varient product',
		Purchase: 'Purchase',
		PurchaseCreateDescription: 'Add a new purchase bill',
		PurchaseCreateTitle: 'New Purchase invoice',
		PurchasePageDescription: 'Shows the list of all purchases',
		PurchasePageTItle: 'All Purchases list',
		Quantity: 'Quantity',
		ReceiptCreateDescription: 'Add a new receipt',
		ReceiptCreateTitle: 'Add Receipt',
		ReceiptList: 'Receipt Entries',
		ReceiptTabDescription: 'List of all receipt entries',
		ReceivedAmount: 'Received Amount',
		ReferenceNumber: 'Reference Number',
		RoundOff: 'Round Off',
		Sales: 'Sales',
		SalesCreateDescription: 'Create a new sales bill',
		SalesCreateTitle: 'New Sales',
		SalesList: 'Sales List',
		SalesPageDescription: 'Shows the list of all purchases',
		SalesPageTitle: 'All Sales list',
		Settings: 'Settings',
		SGST: 'SGST',
		StartingDate: 'Starting Date',
		State: 'State',
		Status: 'Status',
		SubmitButton: 'Submit',
		SubTotal: 'Sub Total',
		SupplierCreateDescription: 'Create a new supplier',
		SupplierCreateTitle: 'New Supplier',
		SupplierDetails: 'Supplier Details',
		SupplierName: 'Supplier Name',
		SupplierPageTitle: 'Suppliers List',
		SupplierPriceGroup: 'Price Group',
		Suppliers: 'Suppliers',
		Taxable: 'Taxable',
		ToDate: 'To Date',
		Transaction: 'Transaction',
		TransportList: 'Transport List',
		TransportListTitle: 'Transport Details List',
		TransportName: 'Transport Name',
		Type: 'Type',
		UnitSymbol: 'Unit Symbol',
		UnitTab: 'Units',
		UnitTabTitle: 'Product units',
		Users: 'Users',

		Submit: 'Submit',
		Cancel: 'Cancel',
		ActiveStatus: 'Active Status',
		Dashboard: 'Telugu Dashboard',
		eCommerce: 'commerce électronique',
		Apps: 'APPLICATIONS',
		Todo: 'Faire',
		Chat: 'Bavarder',
		Email: 'Email',
		Calendar: 'Calendrier',
		FullCalendar: 'Calendrier complet',
		SimpleCalendar: 'Calendrier simple',
		Shop: 'Boutique',
		Cart: 'Chariot',
		WishList: 'Liste de souhaits',
		Checkout: 'Check-out',
		UIElements: 'ELEMENTS D\'UI',
		DataList: 'Liste de données',
		ListView: 'Voir la liste',
		ThumbView: 'Thumb View',
		Grid: 'la grille',
		Vuesax: 'Vuesax',
		Tailwind: 'Tailwind',
		Colors: 'Couleurs',
		Card: 'Carte',
		Basic: 'De base',
		Statistics: 'Statistiques',
		Analytics: 'Analytique',
		CardActions: 'Actions de carte',
		CardColors: 'Couleurs de la carte',
		Table: 'Table',
		agGridTable: 'tableau agGrid',
		Components: 'Composants',
		Alert: 'Alerte',
		Avatar: 'Avatar',
		Badge: 'Badge',
		Breadcrumb: 'Breadcrumb',
		Button: 'Bouton',
		ButtonGroup: 'Groupe de boutons',
		Chip: 'Puce',
		Collapse: 'Effondrer',
		Dialogs: 'Dialogues',
		Divider: 'Diviseur',
		DropDown: 'Menu déroulant',
		List: 'liste',
		Loading: 'Chargement',
		Navbar: 'Barre de navigation',
		Notifications: 'Les notifications',
		Pagination: 'Pagination',
		Popup: 'Apparaitre',
		Progress: 'Le progrès',
		Sidebar: 'Barre latérale',
		Slider: 'Curseur',
		Tabs: 'Onglets',
		Tooltip: 'Info-bulle',
		Upload: 'Télécharger',
		FormsAndTable: 'Formulaires et tableau',
		FormElements: 'Éléments de formulaire',
		Select: 'Sélectionner',
		Switch: 'Commutateur',
		Checkbox: 'Case à cocher',
		Radio: 'Radio',
		Input: 'Contribution',
		NumberInput: 'Nombre d\'entrée',
		Textarea: 'Textarea',
		FormLayouts: 'Dispositions de formulaire',
		FormWizard: 'Assistant de formulaire',
		FormValidation: 'Validation du formulaire',
		FormInputGroup: 'Groupe de saisie de formulaire',
		Pages: 'Pages',
		Authentication: 'Authentification',
		Register: 'registre',
		ForgotPassword: 'Mot de passe oublié',
		ResetPassword: 'réinitialiser le mot de passe',
		LockScreen: 'Écran verrouillé',
		Miscellaneous: 'Divers',
		ComingSoon: 'Arrive bientôt',
		Error: 'Erreur',
		404: '404',
		500: '500',
		NotAuthorized: 'Pas autorisé',
		Maintenance: 'Entretien',
		Profile: 'Profil',
		Invoice: 'Facture d\'achat',
		FAQ: 'FAQ',
		Search: 'Chercher',
		KnowledgeBase: 'Base de connaissances',
		ChartsAndMaps: 'Graphiques Et Cartes',
		Charts: 'Graphiques',
		ApexCharts: 'Apex Charts',
		chartjs: 'chartjs',
		echarts: 'echarts',
		chartist: 'chartist',
		GoogleMap: 'Google Carte',
		Extensions: 'Extensions',
		QuillEditor: 'Éditeur de piquants',
		DragAndDrop: 'Drag & Drop',
		Datepicker: 'Sélecteur de date',
		DatetimePicker: 'Sélecteur de date / heure',
		AccessControl: `Contrôle d'accès`,
		EditorView: `Vue de l'éditeur`,
		AdminView: 'Vue administrateur',
		I18n: 'I18n',
		Carousel: 'Carrousel',
		Clipboard: 'Presse-papiers',
		ContextMenu: 'Menu contextuel',
		StarRatings: 'Classement par étoiles',
		Autocomplete: 'Terminé automatiquement',
		Tree: 'Arbre',
		Others: 'Autre',
		MenuLevels: 'Niveaux de menu',
		MenuLevel2p1: 'Niveau de menu 2.1',
		MenuLevel2p2: 'Niveau de menu 2.2',
		MenuLevel3p1: 'Niveau de menu 3.1',
		MenuLevel3p2: 'Niveau de menu 3.2',
		DisabledMenu: 'Menu désactivé',
		Support: 'Soutien',
		Documentation: 'Documentation',
		RaiseSupport: 'Augmenter le soutien',
		demoTitle: 'Titre de la carte',
		demoText: `Le gâteau au sésame s'enclenche au petit pain au pain d'épices danois J'adore le pain d'épices. Tarte aux pommes et jujubes chupa chups muffin halvah sucette. Tiramisu gâteau au chocolat gâteau d'avoine tiramisu prune sucre. Donut tarte sucrée gâteau à l'avoine dragée gouttes de fruits gâteau au citron en barbe à papa.`,
		ChangePasswordTitle: 'Change password of logged in user',
		ProductionTitle: 'Production List',
		ProductionStart: 'Start Production',
		ProductionStop: 'Stop Production',
		BranchList: 'Branch Settings',
		BranchCreate: 'Create New Branch',
		ProductionRulesUpdate: 'Update Production Rule',
		ChatNew: 'New Chat Entry',
		ChatCustomer: 'Chat with customer',
	},

	mal: {

		Address: 'Address',
		AllLedgers: 'All Ledgers',
		AllLedgersCreateDescription: 'Create a new ledger',
		AllLedgersCreateTitle: 'Add Ledger',
		AllLedgersDescription: 'Displays list of all ledgers',
		AllLedgersTitle: 'All ledgers list',
		Amount: 'Amount',
		Assets: 'Assets',
		CancelButton: 'Cancel',
		ResetButton: 'Reset',
		CESSCreateDesciption: 'Create a new CESS rule',
		CESSCreateTitle: 'Add CESS Rule',
		CESSTab: 'CESS Tax',
		CESSTabDescription: 'Create CESS tax rules',
		CESSTabTitle: 'CESS Tax',
		CGST: 'CGST',
		City: 'City',
		CityCreateTitle: 'Add a new city',
		CityTitle: 'City List',
		ClosingBalance: 'Closing Balance',
		ContactNumber: 'Contact Details',
		ContraList: 'Contra Entries',
		CreditDebit: 'Credit or Debit',
		CurrentBalance: 'Current Balance',
		CurrentStock: 'Current Stock',
		CustomerCreateDescription: 'Create a new Customer',
		CustomerCreateTitle: 'New Customer',
		CustomerDetails: 'Customer Details',
		CustomerName: 'Customer Name',
		CustomerPageTitle: 'Customers List',
		CustomerPriceGroup: 'Customer Price Group',
		Customers: 'Customers',
		Date: 'Date',
		DeliveryDetails: 'Delivery Details',
		Description: 'Description',
		District: 'District',
		EmployeeCreateDescription: 'Create a new Employee',
		EmployeeCreateTitle: 'New Employee',
		EmployeeDetails: 'Employee Details',
		EmployeeName: 'Employee Name',
		EmployeePageTitle: 'Employees List',
		EmployeePriceGroup: 'Price Group',
		Employees: 'Employees',
		Expenses: 'Expenses',
		FixedValue: 'Fixed Value',
		FromDate: 'From Date',
		GroupName: 'Group Name',
		GST: 'GST %',
		GSTSlabCreateTitle: 'Add GST Tax',
		GSTSlabTab: 'GST Tax',
		GSTSlabTitle: 'GST Tax',
		GSTSlabValue: 'GST Tax Value',
		HighestCess: 'Highest Cess Calculation',
		IGST: 'IGST',
		Income: 'Income',
		InvoiceAmount: 'Invoice Amount',
		InvoiceNumber: 'Invoice Number',
		InvoiceSubTotal: 'Taxable Value',
		InvoiceTotal: 'Invoice Total',
		JournalList: 'Journal Entries',
		LastInvoiceNumber: 'Last Sales Invoice Number',
		LedgerCategory: 'Ledger Category',
		LedgerDetails: 'Ledger Details',
		LedgerGroup: 'Ledger Group',
		LedgerGroupCreateDescription: 'Create a new ledger group',
		LedgerGroupCreateTitle: 'Add Ledger Group',
		LedgerGroupDescription: 'List of all ledger groups',
		LedgerGroupTitle: 'Ledger Group List',
		LedgerName: 'Ledger Name',
		LedgerStatement: 'Ledger Statement',
		LedgerStatementDescription: 'Displays the transaction per ledger within a date range or the entire statement',
		LedgerStatementTitle: 'Ledger Statement',
		Liabilities: 'Liabilities',
		ListButton: 'List',
		Login: 'Login creation',
		Logout: 'Logout',
		OpeningBalance: 'Opening Balance',
		ParentGroup: 'Parent Group',
		PaymentCreateDescription: 'Enter the new payment',
		PaymentCreateTitle: 'Add Payment',
		PaymentDescription: 'List of all payment entries',
		PaymentList: 'Payment Entries',
		PaymentMode: 'Payment Mode',
		PaymentModeDescription: 'List of payment modes',
		PaymentModeName: 'Payment Mode Name',
		PaymentModeTitle: 'Mode of payment',
		PaymentType: 'Payment Type',
		Percentage: 'Percentage',
		PerQty: 'Per Qty',
		Phone: 'Phone',
		Price: 'Price (Rs.)',
		PriceGroup: 'Price Group',
		PriceUpdate: 'Price Update',
		PriceUpdateDescription: 'Update the price of products for the corresponding price group',
		PriceUpdateTitle: 'Product Price Update',
		ProductCode: 'Product Code',
		ProductCreateDescription: 'Create a new product',
		ProductCreateTitle: 'Add product',
		ProductCustomCode: 'Product Custom Code',
		ProductEditDescription: 'Make changes to an existing product',
		ProductEditTitle: 'Edit a product',
		ProductGroupCreateTitle: 'Create product group',
		ProductGroupName: 'Product Group Name',
		ProductGroupTab: 'Products Group',
		ProductGroupTitle: 'Product Group list',
		ProductImage: 'Product Image',
		ProductionCost: 'Production Cost',
		ProductName: 'Product Name',
		Products: 'Products',
		ProductsTab: 'Products',
		ProductsTabTitle: 'Products List',
		ProductStatement: 'Product Statement',
		ProductStatementDescription: 'Displays the product transaction with a date range or from the beginning',
		ProductStatementTitle: 'Product Transaction Statement',
		ProductUnit: 'Product Unit',
		ProductVarientCreateDescription: 'Create a new varient to the existing product',
		ProductVarientCreateTitle: 'Add varient product',
		Purchase: 'Purchase',
		PurchaseCreateDescription: 'Add a new purchase bill',
		PurchaseCreateTitle: 'New Purchase invoice',
		PurchasePageDescription: 'Shows the list of all purchases',
		PurchasePageTItle: 'All Purchases list',
		Quantity: 'Quantity',
		ReceiptCreateDescription: 'Add a new receipt',
		ReceiptCreateTitle: 'Add Receipt',
		ReceiptList: 'Receipt Entries',
		ReceiptTabDescription: 'List of all receipt entries',
		ReceivedAmount: 'Received Amount',
		ReferenceNumber: 'Reference Number',
		RoundOff: 'Round Off',
		Sales: 'Sales',
		SalesCreateDescription: 'Create a new sales bill',
		SalesCreateTitle: 'New Sales',
		SalesList: 'Sales List',
		SalesPageDescription: 'Shows the list of all purchases',
		SalesPageTitle: 'All Sales list',
		Settings: 'Settings',
		SGST: 'SGST',
		StartingDate: 'Starting Date',
		State: 'State',
		Status: 'Status',
		SubmitButton: 'Submit',
		SubTotal: 'Sub Total',
		SupplierCreateDescription: 'Create a new supplier',
		SupplierCreateTitle: 'New Supplier',
		SupplierDetails: 'Supplier Details',
		SupplierName: 'Supplier Name',
		SupplierPageTitle: 'Suppliers List',
		SupplierPriceGroup: 'Price Group',
		Suppliers: 'Suppliers',
		Taxable: 'Taxable',
		ToDate: 'To Date',
		Transaction: 'Transaction',
		TransportList: 'Transport List',
		TransportListTitle: 'Transport Details List',
		TransportName: 'Transport Name',
		Type: 'Type',
		UnitSymbol: 'Unit Symbol',
		UnitTab: 'Units',
		UnitTabTitle: 'Product units',
		Users: 'Users',

		Submit: 'Submit',
		Cancel: 'Cancel',
		ActiveStatus: 'Active Status',
		Dashboard: 'Malayalam Dashboard',
		eCommerce: 'comércio eletrônico',
		Apps: 'APPS',
		Todo: 'Façam',
		Chat: 'Bate-papo',
		Email: 'O email',
		Calendar: 'Calendário',
		FullCalendar: 'Calendário Completo',
		SimpleCalendar: 'Calendário Simples',
		Shop: 'fazer compras',
		Cart: 'Carrinho',
		WishList: 'Lista de Desejos',
		Checkout: 'Confira',
		UIElements: 'UI Elementos',
		DataList: 'Lista de dados',
		ListView: 'Exibição de lista',
		ThumbView: 'Thumb View',
		Grid: 'Grade',
		Vuesax: 'Vuesax',
		Tailwind: 'Tailwind',
		Colors: 'Cores',
		Card: 'Cartão',
		Basic: 'Basic',
		Statistics: 'Estatisticas',
		Analytics: 'Analytics',
		CardActions: 'Ações do Cartão',
		CardColors: 'Cores do cartão',
		Table: 'Mesa',
		agGridTable: 'Tabela AgGrid',
		Components: 'Componentes',
		Alert: 'Alerta',
		Avatar: 'Avatar',
		Badge: 'Crachá',
		Breadcrumb: 'Breadcrumb',
		Button: 'Botão',
		ButtonGroup: 'Grupo de botões',
		Chip: 'Lasca',
		Collapse: 'Colapso',
		Dialogs: 'Diálogos',
		Divider: 'Divisor',
		DropDown: 'Suspenso',
		List: 'Lista',
		Loading: 'Carregando',
		Navbar: 'Navbar',
		Notifications: 'Notificações',
		Pagination: 'Paginação',
		Popup: 'Aparecer',
		Progress: 'Progresso',
		Sidebar: 'Barra Lateral',
		Slider: 'Controle Deslizante',
		Tabs: 'Guias',
		Tooltip: 'dica de ferramenta',
		Upload: 'Envio',
		FormsAndTable: 'Formulários e tabela',
		FormElements: 'Elementos do formulário',
		Select: 'Selecione',
		Switch: 'Interruptor',
		Checkbox: 'Caixa de seleção',
		Radio: 'Rádio',
		Input: 'Entrada',
		NumberInput: 'Entrada numérica',
		Textarea: 'Textarea',
		FormLayouts: 'Layouts de formulário',
		FormWizard: 'Assistente de Formulário',
		FormValidation: 'Validação de Formulário',
		FormInputGroup: 'Grupo de entrada de formulário',
		Pages: 'PÁGINAS',
		Authentication: 'Autenticação',
		Register: 'Registo',
		ForgotPassword: 'Esqueceu A Senha',
		ResetPassword: 'Redefinir Senha',
		LockScreen: 'Tela de bloqueio',
		Miscellaneous: 'Diversos',
		ComingSoon: 'Em breve',
		Error: 'Erro',
		404: '404',
		500: '500',
		NotAuthorized: 'Não autorizado',
		Maintenance: 'Manutenção',
		Profile: 'Perfil',
		Invoice: 'Fatura',
		FAQ: 'Perguntas frequentes',
		Search: 'Procurar',
		KnowledgeBase: 'Base de Conhecimento',
		ChartsAndMaps: 'Gráficos E Mapas',
		Charts: 'Gráficos',
		ApexCharts: 'Apex Charts',
		chartjs: 'chartjs',
		echarts: 'echarts',
		chartist: 'chartist',
		GoogleMap: 'Google Mapa',
		Extensions: 'Extensão',
		QuillEditor: 'Quill-Editor',
		DragAndDrop: 'Arraste e solte',
		Datepicker: 'Datepicker',
		DatetimePicker: 'Seletor de data e hora',
		AccessControl: 'Controle de acesso',
		EditorView: 'Editor View',
		AdminView: 'Vista de administração',
		I18n: 'I18n',
		Carousel: 'Carrossel',
		Clipboard: 'Prancheta',
		ContextMenu: 'Menu contextual',
		StarRatings: 'Classificações por estrelas',
		Autocomplete: 'autocompletar',
		Tree: 'Árvore',
		Others: 'Outras',
		MenuLevels: 'Níveis de Menu',
		MenuLevel2p1: 'Nível de menu 2.1',
		MenuLevel2p2: 'Nível de menu 2.2',
		MenuLevel3p1: 'Nível de menu 3.1',
		MenuLevel3p2: 'Nível de menu 3.2',
		DisabledMenu: 'Menu desativado',
		Support: 'Apoio, suporte',
		Documentation: 'Documentação',
		RaiseSupport: 'Levantar Suporte',
		demoTitle: 'Título do cartão',
		demoText: `O sésamo do bolo agarra dinamarquês do pão-de-espécie do queque eu amo o pão-de-espécie. Torta de torta de maçã jujuba chupa chups muffin halvah pirulito. Ameixa do açúcar do maçapão do tiramisu do bolo da aveia do bolo de chocolate. Bolo de aveia de torta doce rosquinha dragée fruitcake algodão doce gotas de limão.`,
		ChangePasswordTitle: 'Change password of logged in user',
		ProductionTitle: 'Production List',
		ProductionStart: 'Start Production',
		ProductionStop: 'Stop Production',
		BranchList: 'Branch Settings',
		BranchCreate: 'Create New Branch',
		ProductionRulesUpdate: 'Update Production Rule',
		ChatNew: 'New Chat Entry',
		ChatCustomer: 'Chat with customer',
	},

	kan: {

		Address: 'Address',
		AllLedgers: 'All Ledgers',
		AllLedgersCreateDescription: 'Create a new ledger',
		AllLedgersCreateTitle: 'Add Ledger',
		AllLedgersDescription: 'Displays list of all ledgers',
		AllLedgersTitle: 'All ledgers list',
		Amount: 'Amount',
		Assets: 'Assets',
		CancelButton: 'Cancel',
		ResetButton: 'Reset',
		CESSCreateDesciption: 'Create a new CESS rule',
		CESSCreateTitle: 'Add CESS Rule',
		CESSTab: 'CESS Tax',
		CESSTabDescription: 'Create CESS tax rules',
		CESSTabTitle: 'CESS Tax',
		CGST: 'CGST',
		City: 'City',
		CityCreateTitle: 'Add a new city',
		CityTitle: 'City List',
		ClosingBalance: 'Closing Balance',
		ContactNumber: 'Contact Details',
		ContraList: 'Contra Entries',
		CreditDebit: 'Credit or Debit',
		CurrentBalance: 'Current Balance',
		CurrentStock: 'Current Stock',
		CustomerCreateDescription: 'Create a new Customer',
		CustomerCreateTitle: 'New Customer',
		CustomerDetails: 'Customer Details',
		CustomerName: 'Customer Name',
		CustomerPageTitle: 'Customers List',
		CustomerPriceGroup: 'Customer Price Group',
		Customers: 'Customers',
		Date: 'Date',
		DeliveryDetails: 'Delivery Details',
		Description: 'Description',
		District: 'District',
		EmployeeCreateDescription: 'Create a new Employee',
		EmployeeCreateTitle: 'New Employee',
		EmployeeDetails: 'Employee Details',
		EmployeeName: 'Employee Name',
		EmployeePageTitle: 'Employees List',
		EmployeePriceGroup: 'Price Group',
		Employees: 'Employees',
		Expenses: 'Expenses',
		FixedValue: 'Fixed Value',
		FromDate: 'From Date',
		GroupName: 'Group Name',
		GST: 'GST %',
		GSTSlabCreateTitle: 'Add GST Tax',
		GSTSlabTab: 'GST Tax',
		GSTSlabTitle: 'GST Tax',
		GSTSlabValue: 'GST Tax Value',
		HighestCess: 'Highest Cess Calculation',
		IGST: 'IGST',
		Income: 'Income',
		InvoiceAmount: 'Invoice Amount',
		InvoiceNumber: 'Invoice Number',
		InvoiceSubTotal: 'Taxable Value',
		InvoiceTotal: 'Invoice Total',
		JournalList: 'Journal Entries',
		LastInvoiceNumber: 'Last Sales Invoice Number',
		LedgerCategory: 'Ledger Category',
		LedgerDetails: 'Ledger Details',
		LedgerGroup: 'Ledger Group',
		LedgerGroupCreateDescription: 'Create a new ledger group',
		LedgerGroupCreateTitle: 'Add Ledger Group',
		LedgerGroupDescription: 'List of all ledger groups',
		LedgerGroupTitle: 'Ledger Group List',
		LedgerName: 'Ledger Name',
		LedgerStatement: 'Ledger Statement',
		LedgerStatementDescription: 'Displays the transaction per ledger within a date range or the entire statement',
		LedgerStatementTitle: 'Ledger Statement',
		Liabilities: 'Liabilities',
		ListButton: 'List',
		Login: 'Login creation',
		Logout: 'Logout',
		OpeningBalance: 'Opening Balance',
		ParentGroup: 'Parent Group',
		PaymentCreateDescription: 'Enter the new payment',
		PaymentCreateTitle: 'Add Payment',
		PaymentDescription: 'List of all payment entries',
		PaymentList: 'Payment Entries',
		PaymentMode: 'Payment Mode',
		PaymentModeDescription: 'List of payment modes',
		PaymentModeName: 'Payment Mode Name',
		PaymentModeTitle: 'Mode of payment',
		PaymentType: 'Payment Type',
		Percentage: 'Percentage',
		PerQty: 'Per Qty',
		Phone: 'Phone',
		Price: 'Price (Rs.)',
		PriceGroup: 'Price Group',
		PriceUpdate: 'Price Update',
		PriceUpdateDescription: 'Update the price of products for the corresponding price group',
		PriceUpdateTitle: 'Product Price Update',
		ProductCode: 'Product Code',
		ProductCreateDescription: 'Create a new product',
		ProductCreateTitle: 'Add product',
		ProductCustomCode: 'Product Custom Code',
		ProductEditDescription: 'Make changes to an existing product',
		ProductEditTitle: 'Edit a product',
		ProductGroupCreateTitle: 'Create product group',
		ProductGroupName: 'Product Group Name',
		ProductGroupTab: 'Products Group',
		ProductGroupTitle: 'Product Group list',
		ProductImage: 'Product Image',
		ProductionCost: 'Production Cost',
		ProductName: 'Product Name',
		Products: 'Products',
		ProductsTab: 'Products',
		ProductsTabTitle: 'Products List',
		ProductStatement: 'Product Statement',
		ProductStatementDescription: 'Displays the product transaction with a date range or from the beginning',
		ProductStatementTitle: 'Product Transaction Statement',
		ProductUnit: 'Product Unit',
		ProductVarientCreateDescription: 'Create a new varient to the existing product',
		ProductVarientCreateTitle: 'Add varient product',
		Purchase: 'Purchase',
		PurchaseCreateDescription: 'Add a new purchase bill',
		PurchaseCreateTitle: 'New Purchase invoice',
		PurchasePageDescription: 'Shows the list of all purchases',
		PurchasePageTItle: 'All Purchases list',
		Quantity: 'Quantity',
		ReceiptCreateDescription: 'Add a new receipt',
		ReceiptCreateTitle: 'Add Receipt',
		ReceiptList: 'Receipt Entries',
		ReceiptTabDescription: 'List of all receipt entries',
		ReceivedAmount: 'Received Amount',
		ReferenceNumber: 'Reference Number',
		RoundOff: 'Round Off',
		Sales: 'Sales',
		SalesCreateDescription: 'Create a new sales bill',
		SalesCreateTitle: 'New Sales',
		SalesList: 'Sales List',
		SalesPageDescription: 'Shows the list of all purchases',
		SalesPageTitle: 'All Sales list',
		Settings: 'Settings',
		SGST: 'SGST',
		StartingDate: 'Starting Date',
		State: 'State',
		Status: 'Status',
		SubmitButton: 'Submit',
		SubTotal: 'Sub Total',
		SupplierCreateDescription: 'Create a new supplier',
		SupplierCreateTitle: 'New Supplier',
		SupplierDetails: 'Supplier Details',
		SupplierName: 'Supplier Name',
		SupplierPageTitle: 'Suppliers List',
		SupplierPriceGroup: 'Price Group',
		Suppliers: 'Suppliers',
		Taxable: 'Taxable',
		ToDate: 'To Date',
		Transaction: 'Transaction',
		TransportList: 'Transport List',
		TransportListTitle: 'Transport Details List',
		TransportName: 'Transport Name',
		Type: 'Type',
		UnitSymbol: 'Unit Symbol',
		UnitTab: 'Units',
		UnitTabTitle: 'Product units',
		Users: 'Users',

		Submit: 'Submit',
		Cancel: 'Cancel',
		ActiveStatus: 'Active Status',
		Dashboard: 'Kannadam Dashboard',
		eCommerce: 'comércio eletrônico',
		Apps: 'APPS',
		Todo: 'Façam',
		Chat: 'Bate-papo',
		Email: 'O email',
		Calendar: 'Calendário',
		FullCalendar: 'Calendário Completo',
		SimpleCalendar: 'Calendário Simples',
		Shop: 'fazer compras',
		Cart: 'Carrinho',
		WishList: 'Lista de Desejos',
		Checkout: 'Confira',
		UIElements: 'UI Elementos',
		DataList: 'Lista de dados',
		ListView: 'Exibição de lista',
		ThumbView: 'Thumb View',
		Grid: 'Grade',
		Vuesax: 'Vuesax',
		Tailwind: 'Tailwind',
		Colors: 'Cores',
		Card: 'Cartão',
		Basic: 'Basic',
		Statistics: 'Estatisticas',
		Analytics: 'Analytics',
		CardActions: 'Ações do Cartão',
		CardColors: 'Cores do cartão',
		Table: 'Mesa',
		agGridTable: 'Tabela AgGrid',
		Components: 'Componentes',
		Alert: 'Alerta',
		Avatar: 'Avatar',
		Badge: 'Crachá',
		Breadcrumb: 'Breadcrumb',
		Button: 'Botão',
		ButtonGroup: 'Grupo de botões',
		Chip: 'Lasca',
		Collapse: 'Colapso',
		Dialogs: 'Diálogos',
		Divider: 'Divisor',
		DropDown: 'Suspenso',
		List: 'Lista',
		Loading: 'Carregando',
		Navbar: 'Navbar',
		Notifications: 'Notificações',
		Pagination: 'Paginação',
		Popup: 'Aparecer',
		Progress: 'Progresso',
		Sidebar: 'Barra Lateral',
		Slider: 'Controle Deslizante',
		Tabs: 'Guias',
		Tooltip: 'dica de ferramenta',
		Upload: 'Envio',
		FormsAndTable: 'Formulários e tabela',
		FormElements: 'Elementos do formulário',
		Select: 'Selecione',
		Switch: 'Interruptor',
		Checkbox: 'Caixa de seleção',
		Radio: 'Rádio',
		Input: 'Entrada',
		NumberInput: 'Entrada numérica',
		Textarea: 'Textarea',
		FormLayouts: 'Layouts de formulário',
		FormWizard: 'Assistente de Formulário',
		FormValidation: 'Validação de Formulário',
		FormInputGroup: 'Grupo de entrada de formulário',
		Pages: 'PÁGINAS',
		Authentication: 'Autenticação',
		Register: 'Registo',
		ForgotPassword: 'Esqueceu A Senha',
		ResetPassword: 'Redefinir Senha',
		LockScreen: 'Tela de bloqueio',
		Miscellaneous: 'Diversos',
		ComingSoon: 'Em breve',
		Error: 'Erro',
		404: '404',
		500: '500',
		NotAuthorized: 'Não autorizado',
		Maintenance: 'Manutenção',
		Profile: 'Perfil',
		Invoice: 'Fatura',
		FAQ: 'Perguntas frequentes',
		Search: 'Procurar',
		KnowledgeBase: 'Base de Conhecimento',
		ChartsAndMaps: 'Gráficos E Mapas',
		Charts: 'Gráficos',
		ApexCharts: 'Apex Charts',
		chartjs: 'chartjs',
		echarts: 'echarts',
		chartist: 'chartist',
		GoogleMap: 'Google Mapa',
		Extensions: 'Extensão',
		QuillEditor: 'Quill-Editor',
		DragAndDrop: 'Arraste e solte',
		Datepicker: 'Datepicker',
		DatetimePicker: 'Seletor de data e hora',
		AccessControl: 'Controle de acesso',
		EditorView: 'Editor View',
		AdminView: 'Vista de administração',
		I18n: 'I18n',
		Carousel: 'Carrossel',
		Clipboard: 'Prancheta',
		ContextMenu: 'Menu contextual',
		StarRatings: 'Classificações por estrelas',
		Autocomplete: 'autocompletar',
		Tree: 'Árvore',
		Others: 'Outras',
		MenuLevels: 'Níveis de Menu',
		MenuLevel2p1: 'Nível de menu 2.1',
		MenuLevel2p2: 'Nível de menu 2.2',
		MenuLevel3p1: 'Nível de menu 3.1',
		MenuLevel3p2: 'Nível de menu 3.2',
		DisabledMenu: 'Menu desativado',
		Support: 'Apoio, suporte',
		Documentation: 'Documentação',
		RaiseSupport: 'Levantar Suporte',
		demoTitle: 'Título do cartão',
		demoText: `O sésamo do bolo agarra dinamarquês do pão-de-espécie do queque eu amo o pão-de-espécie. Torta de torta de maçã jujuba chupa chups muffin halvah pirulito. Ameixa do açúcar do maçapão do tiramisu do bolo da aveia do bolo de chocolate. Bolo de aveia de torta doce rosquinha dragée fruitcake algodão doce gotas de limão.`,
		ChangePasswordTitle: 'Change password of logged in user',
		ProductionTitle: 'Production List',
		ProductionStart: 'Start Production',
		ProductionStop: 'Stop Production',
		BranchList: 'Branch Settings',
		BranchCreate: 'Create New Branch',
		ProductionRulesUpdate: 'Update Production Rule',
		ChatNew: 'New Chat Entry',
		ChatCustomer: 'Chat with customer',
	}

}
