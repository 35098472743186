/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


const actions = {

    // ////////////////////////////////////////////
    // SIDEBAR & UI UX
    // ////////////////////////////////////////////

    updateSidebarWidth({ commit }, width) {
      commit('UPDATE_SIDEBAR_WIDTH', width);
    },
    updateI18nLocale({ commit }, locale) {
      commit('UPDATE_I18N_LOCALE', locale);
    },
    toggleContentOverlay({ commit }) {
      commit('TOGGLE_CONTENT_OVERLAY');
    },
    updateTheme({ commit }, val) {
      commit('UPDATE_THEME', val);
    },
    updateUserRole({ commit }, val) {
      commit('UPDATE_USER_ROLE', val);
    },
    updateWindowWidth({ commit }, width) {
      commit('UPDATE_WINDOW_WIDTH', width);
    },


    // ////////////////////////////////////////////
    // COMPONENT
    // ////////////////////////////////////////////

    // VxAutoSuggest
    updateStarredPage({ commit }, payload) {
      commit('UPDATE_STARRED_PAGE', payload)
    },

    //  The Navbar
    arrangeStarredPagesLimited({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_LIMITED', list)
    },
    arrangeStarredPagesMore({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_MORE', list)
    },

    loadNavbarList({ commit }, payload){

      var output="";
      var successTxt="";
      var errorTxt="";
      var flag=0;
      var records=[];
      var totalRecords=0;

      var url=payload.list_url;
      console.log('url='+url);

      var upload = {
        'token' : payload.token,
        'where' : payload.list_where
      };

      payload.loading({ type:'material', });

      payload.http({
        url: url,
        method: 'POST',
        data: upload,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then(function (response) {
        // payload.loading.close();
        console.log('loadNavbarList response='+JSON.stringify(response));
        output = response.data.output;
        flag = response.data.flag;
        successTxt = response.data.success;
        errorTxt = response.data.error;
        console.log('output='+output+', flag='+flag);
        console.log('successTxt='+successTxt+', errorTxt='+errorTxt);
        records = response.data.records;
        totalRecords = records.data.length;
        console.log('totalRecords='+totalRecords+', records='+JSON.stringify(records));
        if (flag == 1) {
          for (var i = 0; i < totalRecords; i++) {
            var payload = records.data[i];
            console.log('payload='+JSON.stringify(payload));
            commit('loadNavbarList', payload);
          }
          /*
          payload.notify({
            title:'Success',
            text: successTxt,
            color:'success',
            iconPack: 'feather',
            icon:'icon-check',
            position: 'top-center'
          });
          */
        }else{
          /*
          payload.notify({
            title:'Failed',
            text: errorTxt,
            color:'warning',
            iconPack: 'feather',
            icon:'icon-alert-circle',
            position: 'top-center'
          });
          */
        }
      })
      .catch(function (error) {
        console.log('error='+error);
      });
    },
}

export default actions
