<template>
  <div class="mt-3" id="nav-menu-lists">
    <b-navbar toggleable="lg">
      <b-navbar-nav>
        <template v-for="(headerMenuItem, index) in HeaderMenuItems">
          <parent-menu-item
            :key="index"
            v-if="!headerMenuItem.submenu"
            :menu="headerMenuItem"
            :menuId="index"
          >
          </parent-menu-item>
          <template v-else>
            <parent-menu-group
              :key="index"
              :menu="headerMenuItem"
              :menuId="index"
            >
            </parent-menu-group>
          </template>
        </template>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
import common from "@/Common.vue";
import ParentMenuItem from "@/components/ParentMenuItem.vue";
import ParentMenuGroup from "@/components/ParentMenuGroup.vue";
export default {
  mixins: [common],
  components: {
    ParentMenuItem,
    ParentMenuGroup,
  },
  data() {
    return {
      showBtn: false,
      HeaderMenu: "",
      HeaderMenuItems: [],
    };
  },
  methods: {
    changeColor() {
      console.log("changeColor called");
    },
    refreshPageData() {
      console.log("refreshPageData called");
      var selectbox1_source = "HeaderMenu";
      var selectbox1_destination = "HeaderMenuItems";
      var selectbox1_url = "api/menu/lists";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>

<style lang="scss" scoped>
#nav-menu-lists {
  .v-application ul,
  .v-application ol {
    padding-left: 0px !important;
  }
}
</style>