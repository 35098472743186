/*=========================================================================================
  File Name: i18n.js
  Description: i18n configuration file. Imports i18n data.
  ----------------------------------------------------------------------------------------
  Item Name: Vaagai Tecknowledge - Easy Admin
  Author: Web Team
  Author URL: http://www.vaagai.org.in/
==========================================================================================*/


import Vue from 'vue'
import VueI18n from 'vue-i18n'
import i18nData from './i18nData'
import companyConfig from '@/company_config.json';

// Default Language get from companyConfig

var DefaultLanguage = companyConfig.DefaultLanguage;

Vue.use(VueI18n)

export default new VueI18n({
  locale: DefaultLanguage, // set default locale
  messages: i18nData,
})