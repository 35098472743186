<template>
  <b-dropdown-item href="#" id="child-menu-item">
    <router-link :to="menu.MenuLink" v-slot="{ navigate }">
      <a :href="`#${menu.MenuLink}`" class="my-icon" @click="navigate" :style="menu.MenuStyle">
        <i class="my-icon" :class="menu.MenuIcon" :style="menu.MenuStyle"></i>
        <span class="menu-text"> {{ menu.MenuText }} </span>
      </a>
    </router-link>
  </b-dropdown-item>
</template>
  <script>
export default {
  props: {
    menu: {
      type: Object,
      required: true,
    },
    menuId: {
      required: true,
    },
  },
  mounted() {
    console.log("menu item=" + JSON.stringify(this.menu));
  },
};
</script>
  <style lang="scss">
</style>