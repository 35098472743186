<template>
  <b-nav-item-dropdown
    variant="link"
    toggle-class="text-decoration-none"
    no-caret
    id="parent-menu-group"
    menu-class="my-parent-menu"
  >
    <template #button-content>
      <h5 href="#" :style="menu.MenuStyle">
        <i class="menu-icon" :class="menu.MenuIcon" :style="menu.MenuStyle"></i>
        <span class="menu-text"> {{ menu.MenuText }} </span>
        <b-icon icon="caret-down-fill" font-scale="0.9" />
      </h5>
    </template>
    <template v-for="(menuItem, index) in menu.submenu">
      <child-sub-menu-item
        :key="index"
        v-if="!menuItem.submenu"
        :menu="menuItem"
        :menuId="index"
      >
      </child-sub-menu-item>
      <template v-else>
        <b-dropdown
          :key="index"
          id="dropdown-2"
          text="Dropdown Button 2"
          class="m-md-2"
        >
          <b-dropdown-item>First Action 2</b-dropdown-item>
          <b-dropdown-item>Second Action 2</b-dropdown-item>
        </b-dropdown>
        <!-- <parent-child-menu :menu="menuItem" :menuId="index">
              </parent-child-menu> -->
      </template>
    </template>
  </b-nav-item-dropdown>
</template>
  <script>
import ChildSubMenuItem from "@/components/ChildSubMenuItem.vue";
//   import ParentChildMenu from "@/view/layout/header/ParentChildMenu.vue";
export default {
  components: {
    ChildSubMenuItem,
    //   ParentChildMenu
  },
  props: {
    menu: {
      type: Object,
      required: true,
    },
    menuId: {
      required: true,
    },
  },
  mounted() {
    console.log("parent menu group=" + JSON.stringify(this.menu));
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
};
</script>
<style lang="scss">
#parent-menu-group {
  .my-parent-menu{
    padding-left: 0px !important;
  }
}
</style>