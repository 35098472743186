/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

Vue.use(Vuex)

import moduleBranch from './branchStore/moduleBranch.js'
import moduleParty from './partyStore/moduleParty.js'
import moduleChatHistory from './chatHistoryStore/moduleChatHistory.js'

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    branchStore: moduleBranch,
    partyStore: moduleParty,
    chatHistoryStore: moduleChatHistory,
  },
  plugins: [createPersistedState()],
  strict: process.env.NODE_ENV !== 'Production'
})
