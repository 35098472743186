<template>
  <!--begin::Footer-->
  <div class="footer py-4 d-flex flex-lg-column" id="kt_footer">
    <v-row wrap>
      <v-col align="center" cols="12" md="3">
        Made with <v-icon left color="red"> mdi-heart </v-icon> by
        <a href="https://vaagai.org.in" target="_blank">Vaagai Tecknowlege</a>
      </v-col>
      <v-col align="center" cols="12" md="7">
        {{ VersionInfo }}
      </v-col>
      <v-col align="center" cols="12" md="2">
        App version {{ AppVersion }}
      </v-col>
    </v-row>
  </div>
  <!--end::Footer-->
</template>

<script>
import { version } from "/package.json";
import common from "@/Common.vue";

export default {
  mixins: [common],
  data() {
    return {
      CurrentYear: new Date().getFullYear(),
      AppVersion: version,
      VersionInfo: "",
    };
  },
  beforeMount() {
    console.log("beforeMount called");
    var url2 = "api/pages/version-info";
    var condition2 = {
      UserInterface: 1,
    };
    this.getPageDetails("VersionInfo", "", url2, condition2);
  },
};
</script>