/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import themeConfig from '@/themeConfig.js'

// var navbarSearchAndPinList = {}
/*
var navbarSearchAndPinList = {
  actionIcon: 'StarIcon',
  highlightColor: 'warning',
  data: []
}
*/

const state = {
  isSidebarActive: true,
  breakpoint: null,
  sidebarWidth: "default",
  reduceButton: themeConfig.sidebarCollapsed,
  bodyOverlay: false,
  sidebarItemsMin: false,
  theme: themeConfig.theme || 'light',
  AppActiveUser: {
    id: 0,
    name: 'John Doe',
    about: 'Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.',
    img: 'avatar-s-11.png',
    status: 'online',
  },
  themePrimaryColor: themeConfig.primary,
  userRole: null,
  token: null,

  // Can be used to get current window with
  // Note: Above breakpoint state is for internal use of sidebar component
  windowWidth: null,

  CurrentBranchCode: '',
  CurrentBranchName: '',

  PrimaryBranchCode: '',
  PrimaryBranchName: '',
}

export default state
